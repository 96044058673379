
import React from 'react';
import './about.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExitButton } from '../util.components.js/exitButton';

export function About(props) {
    return (
        <div className="about-content">
            <div className="title"><ExitButton onClick={()=>props.close()}></ExitButton><h1>About</h1></div>
            <p>
                This is Yao's portalfolio website.<br />
                This website is currently is build by 4 different projects, one for main page and three small tasks.<br />
                The four projects are all deployed on AWS EC2 and S3 Static web hosting.<br />
                <ul>
                    <li><p>About the portalfolio project(welcome pages and menus).</p>
                        <ul>
                            <li>Front end only React project.</li>
                            <li>Deployed on AWS S3 Static web hosting.</li>
                            <li>Used AWS CodeCommit, CodeBuild and CodeDeploy of the whole process of AWS pipeline.</li>
                        </ul>
                    </li>
                </ul>
                Contact Email: sruotong@gmail.com
            </p>
        </div>
    );
}