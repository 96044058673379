import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import './menu.scss';


export function Menubar(props) {
    return (<header>
        <Row className="justify-content-md-center">
        <Col xl="1" lg="1" md="2" sm="2" xs="12" className="align-self-center"></Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => {props.onClick();props.showAbout(true)}}>About</h3></Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => props.onClick()}>Home</h3></Col>
            {/* <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => window.location.href = 'http://apps.sruotong.com:8082'}>DopplerReact</h3></Col> */}
            {/* <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => window.location.href = 'http://apps.sruotong.com:8081'}>Doppler</h3></Col> */}
            <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => window.location.href = 'http://apps.sruotong.com:8080'}>Flickr</h3></Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => window.location.href = 'http://apps.sruotong.com:8084'}>Puzzler</h3></Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="12" className="align-self-center"><h3 className="menulinks" onClick={() => window.location.href = 'http://apps.sruotong.com:8085'}>Rss Feed</h3></Col>
        </Row>
    </header>);
}