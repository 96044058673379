import React from 'react';
import './App.css';
import { Menubar } from './menu/menu'
import { About } from './about/about'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

library.add(faTimesCircle)

export class App extends React.Component {

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isAbout: false,
      isMenu: false,
      isBoxChecked: false,
      style: {
        backgroundPosition: 'center'
      }
    };
  }

  updatePointer(e) {
    this.setState({
      style: {
        x: e.clientX / 20 - window.innerWidth * 0.05 + 'px',
        y: e.clientY / 20 - window.innerHeight * 0.05 + 'px'
      }
    });
  }

  menuHover() {
    const wrapper = this.wrapperRef.current;
    wrapper.classList.toggle('is-nav-open');
    this.setState({
      isMenu: true,
      isBoxChecked: !this.state.isBoxChecked,
    });
  }

  showAbout(showAbout) {

    if (showAbout != null) {
      this.setState(
        {
          isAbout: showAbout
        }
      );
    }
  }

  render() {

    let content = !this.state.isAbout ? <div>
      <h1 className="menu-icon">Welcome!</h1>
      <p>
        I'm Yao, a developer, lives in Sydney and codes in Sydney.
        </p>
    </div> : <About close={() => this.showAbout(false)}></About>;

    return (
      <div className="App">
        <Menubar onClick={i => this.menuHover()} showAbout={i => this.showAbout(i)}></Menubar>
        <div className="App-header" ref={this.wrapperRef} style={{ backgroundPosition: `${this.state.style.x} ${this.state.style.y}` }} onMouseMove={this.updatePointer.bind(this)}>
          {
            !this.state.isAbout && <div id="menu-container">
              <input type="checkbox" checked={this.state.isBoxChecked} onClick={() => this.menuHover()} />
              <span id="span1"></span>
              <span id="span2"></span>
              <span id="span3"></span>
              <p id="click">MENU</p>
            </div>
          }
          {content}
        </div>
      </div>
    );
  }

}
export default App;
